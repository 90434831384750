import React from 'react';
import { makeStyles } from '@material-ui/core';
// import HorizontalLinearStepper from './sdctech/StepperComponent';
import VerticalLinearStepper from './sdctech/VerticalStepper';

const useStyles = makeStyles(() => ({
  div: {
	padding: '40% 0px',
	textAlign: 'center'
  }
}));

const SdcTech = () => {
  const classes = useStyles();

  return (
    <div className={classes.div}>
		{/* <HorizontalLinearStepper /> */}
      <VerticalLinearStepper />
    </div>
  );
};

export default SdcTech;
