import React from 'react';
import DrawerLayout from './DrawerLayout';
import BlogsContainer from './blog/BlogsContainer';
import Community from './community/Community';
import Help from './Help';
import OrganizeRide from './OrganizeRide';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export default function HomeContainer() {
  const location = useLocation();
  const user = useSelector((state) => state.user.user);
  console.log(user)

  const selectedDrawerItem = location.state?.selectedSection ? location.state.selectedSection : "Blog";

  const renderSelectedDrawerContents = () => {
    switch (selectedDrawerItem) {
      case "Blog":
        return <BlogsContainer user={user} />;
      case "Community Chat":
        return <Community user={user} />;
      case "Emergency Help":
        return <Help />;
      case "Organize Rides":
        return <OrganizeRide />;
      default:
        return <BlogsContainer user={user} />;
    }
  };

  return (
    <DrawerLayout>
      {user && renderSelectedDrawerContents()}
    </DrawerLayout>
  );
}
